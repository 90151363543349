import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import LogoWhite from "../images/logo-white.svg"
import SEO from "../components/seo"

const Layout = ({ children }) => {
  return (
    <>
      <SEO title="Domestic & Commercial Window Cleaning in Essex" />
      <Header />
      <main style={{ marginTop: "56px" }} className="myContainer bg-white z-10">
        {children}
      </main>
      <footer className="py-5 bg-sjh-primary text-white">
        <div className="container flex justify-between mb-4">
          <img alt="SJH Cleaning Logo" className="h-12 mb-3" src={LogoWhite} />
          <div className="grid grid-flow-row md:grid-flow-col items-center gap-2 md:gap-4">
            <p className="mb-0">INFO@SJHCLEANING.CO.UK</p>
            <p className="mb-0">07989 202460</p>
          </div>
        </div>
        <div className="container grid md:grid-cols-2 text-center md:text-left">
          <p>© {new Date().getFullYear()} SJH CLeaning</p>
          <p className="md:justify-self-end">
            Another cracking site built by{" "}
            <a href="https://www.kozocreative.com">Kozo Creative</a>
          </p>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
