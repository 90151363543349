import { Link } from "gatsby"
import { arrayOf, object, node, string } from "prop-types"
import React, { useState, useRef, useEffect } from "react"
import { FaCaretDown, FaBars } from "react-icons/fa"
import Logo from "../images/logo.svg"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const Header = () => {
  gsap.registerPlugin(ScrollTrigger)
  const [visible, setVisible] = useState(true)
  const [open, toggle] = useState(false)
  let header = useRef(null)
  let logo = useRef(null)
  useEffect(() => {
    ScrollTrigger.create({
      onUpdate: self =>
        self.direction === 1 ? setVisible("-100%") : setVisible(0),
    })
    return () => {
      ScrollTrigger.kill()
    }
  }, [])
  useEffect(() => {
    gsap.to(header.current, {
      y: visible,
    })
    gsap.to(logo.current, { y: visible })
  }, [visible])
  return (
    <header
      className="fixed z-30 py-2 bg-white top-0 left-0 w-full"
      ref={header}
    >
      <div className="container pl-24 md:pl-32 flex items-center justify-end md:justify-between relative">
        <Link to="/" className="absolute z-30 top-0 left-0">
          <img
            alt="SJH Cleaning Logo"
            className="w-24 md:w-32 ml-2"
            src={Logo}
            ref={logo}
          />
        </Link>
        <FaBars
          onClick={() => {
            toggle(!open)
          }}
          className="cursor-pointer md:hidden z-30 text-xl my-2"
        />
        <div
          className={`fixed md:static flex flex-col md:flex-row items-end md:items-center text-right md:text-left justify-between top-0 right-0 w-full h-full md:h-auto bg-sjh-pale md:bg-transparent text-xl md:text-base p-6 pt-16 md:p-0 z-20 transform md:transform-none transition-transform duration-200 ${
            open ? `translate-x-0` : `translate-x-full`
          }`}
        >
          <nav className="uppercase flex-grow-0 w-full md:w-auto">
            <ul className="m-0 grid grid-flow-row md:grid-flow-col md:ml-3 gap-4">
              <Dropdown
                items={[
                  {
                    title: `Domestic & Commercial Window Cleaning`,
                    link: "/services/window-cleaning",
                  },
                  {
                    title: `Gutters, Soffits & Fascia Cleaning`,
                    link: "/services/gutters-soffits-fascia-cleaning",
                  },
                  {
                    title: `Gutter Clearance`,
                    link: "/services/gutter-clearance",
                  },
                  {
                    title: `Conservatory Cleaning`,
                    link: "/services/conservatory-cleaning",
                  },
                ]}
              >
                Services
              </Dropdown>
              <NavItem link="/contact">Contact</NavItem>
            </ul>
          </nav>
          <div className="grid grid-flow-row md:grid-flow-col items-center gap-2 md:gap-4">
            <p className="mb-0">
              <a href="mailto:info@sjhcleaning.co.uk">INFO@SJHCLEANING.CO.UK</a>
            </p>
            <p className="mb-0">
              <a href="tel:+447989 202460">07989 202460</a>
            </p>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header

const NavItem = ({ children, link }) => {
  return (
    <li className="p-2">
      <Link to={link}>{children}</Link>
    </li>
  )
}
NavItem.propTypes = {
  children: node.isRequired,
  link: string.isRequired,
}
const Dropdown = ({ children, items }) => {
  const [open, toggle] = useState(false)
  return (
    <div className="relative">
      <li
        className={`cursor-pointer flex items-center justify-end md:justify-start transition-colors duration-300 ${
          open && `bg-sjh-pale`
        }`}
      >
        <button
          className="flex items-center uppercase p-2 rounded-t"
          type="button"
          onClick={() => toggle(!open)}
        >
          {children}{" "}
          <FaCaretDown
            className={`ml-1 transition-transform transform ${
              open ? `rotate-180` : `rotate-0`
            }`}
          />
        </button>
      </li>
      <ul
        className={`absolute bg-sjh-pale origin-top-left right-0 md:left-0 ml-0 w-full md:w-80 shadow-lg md:rounded-sjh z-10 transition-all duration-300 ${
          open
            ? `opacity-100 scale-100`
            : `opacity-0 scale-50 pointer-events-none`
        }`}
      >
        {items.map(({ title, link }) => {
          return (
            <li
              key={title}
              className="py-2 px-4 hover:bg-sjh-primary hover:text-white"
            >
              <Link to={link}>{title}</Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
Dropdown.propTypes = {
  children: node.isRequired,
  items: arrayOf(object),
}
